<template>
    <div v-if="show" class="gst-discount-banner u-height-100 d-flex flex-column align-center justify-center">
        <p class="gst-discount-banner__title">
            {{ title }}
        </p>
        <p v-if="subtitle" class="gst-discount-banner__subtitle">
            {{ subtitle }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'DiscountBanner',
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'discounts.discountBanner'
        },
        props: {
            discounts: {
                type: Array,
                required: true
            }
        },
        computed: {
            show( ) {
                return this.discount && ( this.title || this.subtitle );
            },
            discount( ) {
                return this.discounts[0];
            },
            discountMessages( ) {
                return this.discount.messages;
            },
            title( ) {
                return this.discountMessages.topBanner;
            },
            subtitle( ) {
                return this.discountMessages.bottomBanner;
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-discount-banner {
    height: 48px;
    background: theme-color( 'info' );
    border-radius: 10px;

    .gst-discount-banner__title {
        line-height: normal;
        margin-bottom: theme-spacing( 0 );
        color: theme-color( 'white' );
        font-size: 14px;
        font-weight: 900;
        letter-spacing: -0.357px;
        font-style: normal;
    }

    .gst-discount-banner__subtitle {
        line-height: normal;
        margin-bottom: theme-spacing( 0 );
        color: theme-color( 'quinary' );
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.25px;
        font-style: normal;
    }
}
</style>